<template>
    <div></div>
</template>
<script>
    import KerasJS from 'keras-js'
    export default {
        name: '测试页面',
        mounted() {
        },
        created() {

        },
        methods: {

        },
    }
</script> 