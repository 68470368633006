import { render, staticRenderFns } from "./taskCalendar.vue?vue&type=template&id=67d54597&scoped=true&"
import script from "./taskCalendar.vue?vue&type=script&lang=js&"
export * from "./taskCalendar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67d54597",
  null
  
)

export default component.exports