const menuTemplate = [{
    path: '/',
    name: '任务栏',
    icon: 'el-icon-s-order',
    key: "1"
}]


export {
    menuTemplate
}